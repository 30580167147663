const html = document.querySelector('html');
const openBtn = document.querySelector('#open');
const closeBtn = document.querySelector('#close');
const burger = document.querySelector('#burger');

openBtn.addEventListener('click', () => {
  burger.classList.add('burger--open');
  html.style.overflow = 'hidden';
});

closeBtn.addEventListener('click', () => {
  burger.classList.remove('burger--open');
  html.style.overflow = 'visible';
});
